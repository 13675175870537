body{
    font-family: 'gothambook';
}
.container{ 
    min-height: 100vh; 
    background-color: #fff; 
    background-image: url("../img/bg.png"); 
    width: 100%; 
    background-size: cover; 
    background-position: bottom center; 
    background-repeat: no-repeat; 
    padding-bottom: 150px; 
    position: relative; 
    max-width: 500px;
    border-top: 1px solid transparent;
}

header {
    position: relative;
}
header .logo{
    width: 100px;
    display: block;
    margin: 20px auto;
}
header .icon{
    position: absolute;
    left: 0;
    top:0;
    width: 70px;
}

header .station{
    position: absolute;
    right: 0;
    top:0;
    text-align: right;
    font-family: 'gothambold';
    color: #fff;
    font-size: 15px;
}

#login{
    margin: auto;
    margin-top: 100px;
    width: 90%;
}

#login h1{
    text-align: center;
    font-family: 'gothambold';
    color: #fff;
}

label{
    font-family: 'gothambold';
    color: #fff;
}

input{
    height: 50px;
    font-size: 20px !important;
    border-radius: 10px !important;
    font-family: 'gothambold';
    background-color: #f06470 !important;
    color: #fff !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff !important;
    opacity: 1; /* Firefox */
}

.tmpl-main-btn{
    display: block;
    margin: auto;
    height: 60px;
    width: 150px;
    border-radius: 50vw;
    background-color: #fff;
    font-family: 'gothambold';
    border-radius: 2px solid #000;
}

#landing h1{
    font-family: 'gothambold';
    color : #fff;
    text-align: center;
    margin: 30px auto;
}

#landing .actions{
    display: flex;
    flex-direction: column;
    min-height: 500px;
    justify-content: center;
    align-items: center;
}

#landing .actions .icon{
    width: 150px;
}

#landing .actions .form{
    margin-top: 50px;
}

#landing .actions .form label{

}

.modal-body{
    min-height: 400px;
}

.swal2-title {
    position: relative;
    max-width: 100%;
    margin: 0;
    padding: 0.8em 1em 0;
    color: inherit;
    font-size: 15px !important;
    font-family: "gothambook";
}

.history{
    height: 75vh;
}

.history .item div{
    display: inline-block;
    vertical-align: middle;
    width: 33%;
    font-size: 12px !important;
    font-family: "gothambold";
    color: #fff;
    padding: 5px;
    margin-bottom: 10px;
}

.history .item .item-id{
    width: 25%;
}

.history .item .item-action{
    width: 40%;
}